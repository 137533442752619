<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex align-center mr-5 pt-3 flex-md-wrap col-md-8 col-lg-10">
                    <v-text-field
                        :placeholder="$t('message.crNo')"
                        :value="searchValues.title"
                        autocomplete="new-password"
                        class="filter-width-150 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.title = $event"
                        @click:append="searchNcrs('title')"
                        @click:clear="resetSearch('title')"
                        @keyup.enter.prevent="searchNcrs('title')"
                    />
                    <v-select
                        :items="filterItems.status"
                        :loading="loading.filterItems.status"
                        :placeholder="$t('message.status')"
                        class="filter-width-125 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Status.title"
                        item-value="Status.id"
                        solo
                        v-model="filterValues.status"
                        @change="filterNcrs()"
                    >
                        <template v-slot:item="{ item }">
                            <span class="font-sm">{{ $t('message.' + item.Status.title) }}</span>
                        </template>
                        <template v-slot:selection="{ item }">
                            <span class="font-sm">{{ $t('message.' + item.Status.title) }}</span>
                        </template>
                    </v-select>
                    <v-autocomplete
                        :items="filterItems.salesColleague"
                        :loading="loading.filterItems.salesColleague"
                        :placeholder="$t('message.sc')"
                        class="filter-width-125 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Salescontact.name"
                        item-value="Salescontact.id"
                        solo
                        v-model="filterValues.salesColleague"
                        @change="filterNcrs()"
                    />
                    <v-autocomplete
                        :items="filterItems.buyer"
                        :loading="loading.filterItems.buyer"
                        :placeholder="$t('message.buyer')"
                        class="filter-width-125 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Customer.title"
                        item-value="Customer.id"
                        solo
                        v-model="filterValues.buyer"
                        @change="filterNcrs()"
                    />
                    <v-autocomplete
                        :items="filterItems.supplier"
                        :loading="loading.filterItems.supplier"
                        :placeholder="$t('message.spl')"
                        class="filter-width-125 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Supplier.title"
                        item-value="Supplier.id"
                        solo
                        v-model="filterValues.supplier"
                        @change="filterNcrs()"
                    />
                    <div class="col-12 height-10px pa-0" v-if="$vuetify.breakpoint.mdAndDown"></div>
                    <v-autocomplete
                        :class="'filter-width-125' + ($vuetify.breakpoint.lgAndUp ? ' ml-2' : '')"
                        :items="filterItems.species"
                        :loading="loading.filterItems.species"
                        :placeholder="$t('message.species')"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Species.title"
                        item-value="Species.id"
                        solo
                        v-model="filterValues.species"
                        @change="filterNcrs()"
                    />
                    <v-autocomplete
                        :items="filterItems.origin"
                        :loading="loading.filterItems.origin"
                        :placeholder="$t('message.origin')"
                        class="filter-width-125 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Country.name"
                        item-value="Country.id"
                        solo
                        v-model="filterValues.origin"
                        @change="filterNcrs()"
                    />
                    <v-autocomplete
                        :items="filterItems.pod"
                        :loading="loading.filterItems.pod"
                        :placeholder="$t('message.pod')"
                        class="filter-width-125 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Shippingport.title"
                        item-value="Shippingport.id"
                        solo
                        v-model="filterValues.pod"
                        @change="filterNcrs()"
                    />
                    <v-autocomplete
                        :items="filterItems.destination"
                        :loading="loading.filterItems.destination"
                        :placeholder="$t('message.dest')"
                        class="filter-width-125 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Country.name"
                        item-value="Country.id"
                        solo
                        v-model="filterValues.destination"
                        @change="filterNcrs()"
                    />
                    <v-btn
                        :loading="loading.clear"
                        class="ml-2 px-2"
                        @click="clearFilters"
                    >{{ $t('message.clear') }}</v-btn>
                </div>
                <v-spacer/>
                <ExportTableJson
                    :export-conditions="exportConditions"
                    :export-data="allContractRequests"
                    :export-fields="headers"
                    :export-source="'ncrs'"
                    class="ml-3"
                />
            </v-row>
            <v-row no-gutters>
                <v-col class="d-flex flex-row pt-3">
                    <div class="font-sm">
                        <v-icon small color="gray lighten-1">fiber_manual_record</v-icon>{{ $t('message.open') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="yellow lighten-1">fiber_manual_record</v-icon>{{ $t('message.submitted') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="green lighten-1">fiber_manual_record</v-icon>{{ $t('message.approved') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="red lighten-4">fiber_manual_record</v-icon>{{ $t('message.declined') }}
                    </div>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.ncrs"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
            :items="allContractRequests"
            :options="tableOptions"
            :server-items-length="totalContractRequests"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="ncrTable"
            item-key="Ncr.id"
            @update:options="updateDataTable"
        >
            <template v-slot:header.Ncr.Specifications="{ header }">
                <div>
                    {{ $t('message.species') }}
                    <span class="float-right">{{ $t('message.qty') }}</span>
                </div>
            </template>
            <template v-slot:item.Ncr.id="{ item }">
                <div  class="d-flex flex-row align-center justify-center" style="height: 100%">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="updateNcr(item.Ncr.id)" v-if="$can('update','Ncr')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ item.Ncr.status_id == 3 ? $t('message.viewNcr') : $t('message.updateNcr') }}</v-list-item>
                            <v-list-item v-if="item.Ncr.status_id == 1 && $can('delete','Ncr')" class="font-sm" @click="cancelNcr(item.Ncr.id, item.Ncr.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelNcr') }}</v-list-item>
<!--                            <v-list-item class="font-sm" @click="viewPdf(item.Ncr.id)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>-->
                            <PrintNcrButton
                                :update-mode="true"
                                :list-mode="true"
                                :ncr-id="item.Ncr.id"
                                :mergeable-docs="item.Ncr.mergeableDocs"
                                :ncr-number="item.Ncr.title"
                            />
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <template v-slot:item.Ncr.ncrdate="{ item }">
                {{ item.Ncr.ncrdate != '0000-00-00' ? formatDate(item.Ncr.ncrdate) : '' }}
            </template>
            <template v-slot:item.Ncr.title="{ item }">
                <span class="text-no-wrap font-weight-bold">{{ item.Ncr.title }}</span>
            </template>
            <template v-slot:item.Ncr.ncrsubmit_date="{ item }">
                {{ item.Ncr.ncrsubmit_date != '0000-00-00' ? formatDate(item.Ncr.ncrsubmit_date) : '' }}
            </template>
            <template v-slot:item.Ncr.Customer.title="{ item }">
                {{ item.Ncr.Customer.otsname != '' ? item.Ncr.Customer.otsname : item.Ncr.Customer.title }}
            </template>
            <template v-slot:item.Ncr.Supplier.code="{ item }">
                {{ item.Ncr.Supplier.code != '' ? item.Ncr.Supplier.code : item.Ncr.Supplier.title }}
            </template>
            <template v-slot:item.Ncr.amount="{ item }">
                <div class="flex flex-row pl-0">
                    <span class="pr-1">{{ currency(item.Ncr.currency_id) }}</span>
                    <span>{{ formatThisNumber(item.Ncr.amount,'0,0') }}</span>
                </div>
            </template>
            <template v-slot:item.Ncr.cost="{ item }">
                <div class="flex flex-row pl-0 align-start">
                    <span class="pr-1">{{ currency(item.Ncr.currency_id) }}</span>
                    <span>{{ formatThisNumber(item.Ncr.cost,'0,0') }}</span>
                </div>
            </template>
            <template v-slot:item.Ncr.margin="{ item }">
                <div class="flex flex-row pl-0 align-start">
                    <span class="pr-1">{{ currency(item.Ncr.currency_id) }}</span>
                    <span>{{ formatThisNumber(item.Ncr.margin,'0,0') }}</span>
                </div>
            </template>
            <template v-slot:item.Ncr.containersize="{ item }">
                <div class="text-no-wrap">
                    <span v-if=" [1,2,3,5].includes(item.Ncr.containersize_id) ">{{ item.Ncr.containercount }}</span>
                    <span v-if=" [1,2,3,5].includes(item.Ncr.containersize_id) "> X </span>
                    <span>{{ getContainerSize(item.Ncr.containersize_id) }}</span>
                </div>
            </template>
            <template v-slot:item.Ncr.Specifications="{ item }">
                <v-row v-for="(specification, idx) in item.Ncr.Specifications" no-gutters v-bind:key="idx">
                    <v-col class="text-no-wrap" :class="idx > 0 ? 'border-top-1' : ''">{{ specification.species }}</v-col>
                    <v-col class="text-right" :class="idx > 0 ? 'border-top-1' : ''">{{ formatThisNumber(specification.volume,'0,0.000') + specification.uofm  }}</v-col>
                </v-row>
            </template>
            <template v-slot:item.Ncr.status_id="{ item }">
                <div class="text-center">
                    <v-icon small :color="item.Ncr.Status.color + ' lighten-1'">fiber_manual_record</v-icon>
                </div>
            </template>
            <template v-slot:item.Contract.id="{ item }">
                <router-link :to="{name: 'update_contract', params: { contractId: item.Contract.id }}" v-if="item.Contract.id != null" class="text-no-wrap">{{ item.Contract.title }}</router-link>
            </template>
            <template v-slot:item.Ncr.margin_pct="{ item }">
                <span>{{ formatThisNumber(getMarginPct(item.Ncr.margin, item.Ncr.amount),'0,0.0') }}</span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {api} from 'Api';
import {formatDate, numberFormat} from "Helpers/helpers";
import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters} from "vuex";
import {v4 as uuidv4} from "uuid";

const ExportTableJson = () => import("Components/Appic/ExportTableJson");
const PrintNcrButton = () => import("Components/Appic/Printing/PrintNcrButton");

export default {
    name: "NcrsListing",
    components: {ExportTableJson, PrintNcrButton},
    data() {
        return {
            convertDialog: false,
            currentFilter: null,
            currentSearch: null,
            dialogs: {
                error:  false,
                error_message: ""
            },
            filterItems: {
                buyer: [],
                destination: [],
                origin: [],
                pod: [],
                salesColleague: [],
                species: [],
                status: [],
                supplier: []
            },
            filterOptions: {},
            filterValues: {
                buyer: null,
                destination: null,
                origin: null,
                pod: null,
                salesColleague: null,
                species: null,
                status: null,
                supplier: null
            },
            exportConditions: {},
            hideDefaultHeader: false,
            hideDefaultFooter: false,
            loader: false,
            loading: {
                clear: false,
                filter: {},
                filterItems: {
                    buyer: false,
                    destination: false,
                    origin: false,
                    pod: false,
                    salesColleague: false,
                    species: false,
                    status: false,
                    supplier: false
                },
                search: false,
                ncrs: false
            },
            searchField: 'Ncr.title', //default search field
            searching: false,
            searchTerm: null,
            searchValues: {
                title: null
            },
            tableOptions: {},
            ncrIdToConvert: null,
            ncrTitleToConvert: null,
        }
    },
    computed: {
        ...mapFields('ncr', {
            allContractRequests: 'allContractRequests',
            totalContractRequests: 'totalContractRequests',
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters([
            'containerSizes',
            'currencies'
        ]),
        defaultItemsPerPage() {
            if(window.screen.height >= 800) return 15
            return 10
        },
        headers() {
            return [
                {
                    id: 0,
                    text: this.$t('message.actions'),
                    value: 'Ncr.id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 1,
                    text: this.$t('message.date'),
                    value: 'Ncr.ncrdate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 2,
                    text: this.$t('message.ncr'),
                    value: 'Ncr.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.salesColleague'),
                    value: 'Ncr.Salescontact.name',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.status'),
                    value: 'Ncr.status_id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 5,
                    text: this.$t('message.contract'),
                    value: 'Contract.id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 6,
                    text: this.$t('message.buyer'),
                    value: 'Ncr.Customer.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 7,
                    text: this.$t('message.spl'),
                    value: 'Ncr.Supplier.code',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-5-pct',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 8,
                    text: this.$t('message.origin'),
                    value: 'Ncr.Origin.name',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 9,
                    text: this.$t('message.pod'),
                    value: 'Ncr.ShippingPort.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 10,
                    text: this.$t('message.destination'),
                    value: 'Ncr.Destination.name',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 11,
                    text: this.$t('message.container'),
                    value: 'Ncr.containersize',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 12,
                    text: this.$t('message.speciesAndQty'),
                    value: 'Ncr.Specifications',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 13,
                    text: this.$t('message.cost'),
                    value: 'Ncr.cost',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 14,
                    text: this.$t('message.value'),
                    value: 'Ncr.amount',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 15,
                    text: this.$t('message.margin'),
                    value: 'Ncr.margin',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 15,
                    text: this.$t('message.mgPct'),
                    value: 'Ncr.margin_pct',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                // {
                //     id: 15,
                //     text: this.$t('message.incoterm'),
                //     value: 'Ncr.Incoterm.title',
                //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                //     searchable: false,
                //     sortable: false
                // },
                // {
                //     id: 4,
                //     text: this.$t('message.submitted'),
                //     value: 'Ncr.ncrsubmit_date',
                //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                //     searchable: false,
                //     sortable: true
                // },
            ]
        },
        itemsPerPageOptions() {
            let options = [10,20,50,-1];
            if(window.screen.height >= 800){
                options = [15,30,50,-1];
            }
            return options;
        },
        searchFields() {
            return this.headers.filter((header)=>header.searchable === true)
        },
    },
    methods: {
        ...mapActions('currency',{
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('ncr',{
            cancelNcrById: 'cancelNcrById',
            getAllNcrs: 'getAllNcrs',
            searchAllNcrs: 'searchAllNcrs',
        }),
        async cancelNcr (val, title) {
            if(await this.$root.$confirm(this.$t('message.cancelNcr') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueNcrCancelAction'), {color: 'orange'})){
                this.cancelNcrById(val)
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.ncrDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loadNcrs()
                        } else {
                            this.$toast.error(this.$t('message.errors.ncrNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.ncrNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        clearFilters(){
            this.loading.clear = true
            this.filterValues.buyer = null
            this.filterValues.destination = null
            this.filterValues.origin = null
            this.filterValues.pod = null
            this.filterValues.salesColleague = null
            this.filterValues.species = null
            this.filterValues.status = 0
            this.filterValues.supplier = null
            this.searching = false
            this.searchValues.title = null
            this.tableOptions.page = 1
            this.tableOptions.sortBy[0] = 'Ncr.title'
            this.tableOptions.sortDesc[0] = true
            this.loading.clear = false
            this.filterNcrs()
        },
        currency(currency_id) {
            return this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        filterNcrs() {
            this.searchValues.title = null
            let noValues = true
            const keys = Object.keys(this.filterValues)
            keys.forEach((key, index) => {
                if(this.filterValues[key] != null) noValues = false
            })
            if(noValues == false) {
                let conditions = []
                if (this.filterValues.buyer != null) conditions.push({
                    field: 'Ncr.customer_id',
                    value: this.filterValues.buyer
                })
                if (this.filterValues.destination != null) conditions.push({
                    field: 'Ncr.destination_id',
                    value: this.filterValues.destination
                })
                if (this.filterValues.origin != null) conditions.push({
                    field: 'Ncr.productorigin_id',
                    value: this.filterValues.origin
                })
                if (this.filterValues.pod != null) conditions.push({
                    field: 'Ncr.shippingport_id',
                    value: this.filterValues.pod
                })
                if (this.filterValues.salesColleague != null) conditions.push({
                    field: 'Ncr.salescontact_id',
                    value: this.filterValues.salesColleague
                })
                if (this.filterValues.species != null) conditions.push({
                    field: 'NcrItem.species_id',
                    value: this.filterValues.species
                })
                if (this.filterValues.status != null) conditions.push({
                    field: 'Ncr.status_id',
                    value: this.filterValues.status
                })
                if (this.filterValues.supplier != null) conditions.push({
                    field: 'Ncr.supplier_id',
                    value: this.filterValues.supplier
                })

                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                if (_.isEqual(this.currentFilter, this.filterValues) === false) {
                    this.tableOptions.page = 1
                    this.currentFilter = _.cloneDeep(this.filterValues)
                }

                if(_.has(this.tableOptions, 'sortBy') == false) {
                    this.tableOptions['sortBy'] = ['Ncr.title']
                }

                if(this.tableOptions.sortBy.length == 0){
                    this.tableOptions['sortBy'] = ['Ncr.title']
                }
                if(_.has(this.tableOptions, 'sortDesc') == false){
                    this.tableOptions['sortDesc'] = [false]
                    if(this.tableOptions.sortBy[0] == 'Ncr.title') {
                        this.tableOptions['sortDesc'] = [true]
                    }
                }
                if(this.tableOptions.sortBy[0] == 'Ncr.ncrdate' && this.tableOptions.sortDesc.length == 0){
                    this.tableOptions['sortDesc'] = [true]
                }

                let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                let payload = {
                    tableOptions: this.tableOptions,
                    conditions: conditions,
                    order: order
                }

                this.hideDefaultFooter = false
                this.loading.ncrs = true;
                this.searchAllNcrs(payload)
                    .then(() => {
                        this.loading.ncrs = false;
                    })
                    .catch(() => {
                        this.loading.ncrs = false;
                    })
                    .finally(() => {
                        this.loading.ncrs = false;
                    })
            } else {
                this.loadNcrs()
            }
        },
        formatDate,
        formatThisNumber(value,format) {
            return numberFormat(value,format)
        },
        getContainerSize(containersize_id) {
            let container = this.containerSizes.find(containerSize => containerSize.Containersize.id == containersize_id)
            if( container ) {
                return container?.Containersize.title
            } else {
                return null
            }
        },
        getMarginPct(margin, value) {
            if(value > 0){
                return (margin / value) * 100
            }
            return 0
        },
        loadFilterItems(filter) {
            return new Promise((resolve, reject) => {
                if(filter && filter.length > 0) {
                    this.loading.filterItems[filter] = true
                    api
                        .get('/ncrs/filter-options/' + filter)
                        .then(response => {
                            if(response.data.status == 'success') {
                                this.filterItems[filter] = response.data.data

                                //add All to some options
                                if(filter == 'status'){
                                    this.filterItems[filter].unshift({Status: {id: 0, title: 'all' }})
                                }

                                this.loading.filterItems[filter] = false
                                resolve('done')
                            } else {
                                this.loading.filterItems[filter] = false
                                reject('error')
                            }
                        })
                        .catch(error => {
                            this.loading.filterItems[filter] = false
                            reject(error)
                        })
                } else {
                    reject('Filter empty')
                }
            })
        },
        loadNcrs() {
            this.hideDefaultFooter = false
            this.loading.ncrs = true;
            let payload = {
                tableOptions: this.tableOptions
            }
            this.getAllNcrs(payload)
                .then(()=>{
                    this.loading.ncrs = false;
                })
                .catch(()=>{
                    this.loading.ncrs = false;
                })
                .finally(()=>{
                    this.loading.ncrs = false;
                })
        },
        toggleConvertDialog (ncr){
            this.convertDialog = !this.convertDialog
            this.ncrIdToConvert = this.convertDialog ? ncr.Ncr.id : null
            this.ncrTitleToConvert = this.convertDialog ? ncr.Ncr.title : null
        },
        resetSearch (filter = null) {
            // this.hideDefaultFooter = false
            // this.loading.ncrs = false
            // this.searchField = 'Ncr.title'; //default search field
            // this.searchTerm = null;
            // this.tableOptions.itemsPerPage = 10
            // this.filterOptions = {};
            this.searching = false
            this.hideDefaultFooter = false
            this.filterValues.status = 0
            this.tableOptions.page = 1
            if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage
            switch(filter){
                case 'title':
                    this.searchValues[filter] = []
                    break
            }
            this.tableOptions.sortBy = []
            this.tableOptions.sortDesc = []
            this.filterNcrs()
        },
        searchNcrs( field ) {
            if(this.searchValues[field] == null){
                this.dialogs.error = true
                this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
            } else {
                this.searching = true
                this.filterValues.buyer = null
                this.filterValues.destination = null
                this.filterValues.origin = null
                this.filterValues.pod = null
                this.filterValues.salesColleague = null
                this.filterValues.species = null
                this.filterValues.status = null
                this.filterValues.supplier = null

                const keys = Object.keys(this.searchValues)
                keys.forEach((key, index) => {
                    if(key != field) this.searchValues[key] = null
                })

                let fieldName = null
                let sortFieldName = null
                switch(field){
                    case 'title':
                        fieldName = 'Ncr.title'
                        sortFieldName = 'Ncr.title'
                        break
                }

                // this.hideDefaultFooter = true
                this.loading.ncrs = true

                let payload = {}
                let order = []

                if (_.isEqual(this.currentSearch, this.searchValues) === false) {
                    //new search = new sort
                    order.push({field: fieldName, direction: field == 'title' ? 'ASC' : 'DESC'})
                    this.tableOptions.sortBy[0] = sortFieldName
                    this.tableOptions.sortDesc[0] = field == 'title' ? false : true
                    this.currentSearch = _.cloneDeep(this.searchValues)
                    //new search new page
                    this.tableOptions.page = 1
                } else {
                    //same search new sort
                    if(this.tableOptions.sortBy.length == 0){
                        this.tableOptions.sortBy[0] = sortFieldName
                    }
                    order.push({field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'})
                }

                if(fieldName != null) {
                    payload = {
                        tableOptions: this.tableOptions,
                        conditions: [
                            {
                                field: fieldName,
                                value: this.searchValues[field]
                            }
                        ],
                        order: order
                    }
                } else {
                    payload = {
                        tableOptions: this.tableOptions,
                        conditions: [],
                        order: order
                    }
                }

                this.searchAllNcrs(payload)
                    .then(() => {
                        this.loading.ncrs = false;
                    })
                    .catch(()=>{
                        this.loading.ncrs = false;
                    })
                    .finally(()=>{
                        this.loading.ncrs = false;
                    })
            }
        },
        updateDataTable(options) {
            this.tableOptions = options
            if(this.searching === true)  {
                // check which field is not empty
                let field = null
                if(this.searchValues.title != null) field = 'title'
                this.searchNcrs(field)
            } else {
                this.filterNcrs()
            }
        },
        updateNcr(val) {
            let tab = window.open('/v1/ncrs/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        viewPdf(ncrId) {
            let document = encodeURIComponent(this.allContractRequests.find( n => n.Ncr.id == ncrId)?.Ncr.title)
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/ncrs/print/'
                + ncrId
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    created() {
        this.searchValues.title = null
        this.filterValues.buyer = null
        this.filterValues.destination = null
        this.filterValues.origin = null
        this.filterValues.pod = null
        this.filterValues.salesColleague = null
        this.filterValues.species = null
        this.filterValues.status = 0
        this.filterValues.supplier = null

        if(this.filterValues.status == 0){
            this.filterNcrs()
        } else {
            this.loadNcrs()
        }

        if(this.filterItems.buyer.length == 0) this.loadFilterItems('buyer')
        if(this.filterItems.destination.length == 0) this.loadFilterItems('destination')
        if(this.filterItems.origin.length == 0) this.loadFilterItems('origin')
        if(this.filterItems.pod.length == 0) this.loadFilterItems('pod')
        if(this.filterItems.salesColleague.length == 0) this.loadFilterItems('salesColleague')
        if(this.filterItems.species.length == 0) this.loadFilterItems('species')
        if(this.filterItems.status.length == 0) this.loadFilterItems('status')
        if(this.filterItems.supplier.length == 0) this.loadFilterItems('supplier')
    },
}
</script>

<style>
.filter-width-125 {
    width: 125px !important;
    max-width: 125px !important;
}
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.height-10px {
    height: 10px;
}
</style>